
body{
  background: url("bg.png");
  animation: bg-amin infinite 20s linear;
}


.App {
  text-align: center;
}

.App-logo {

  height: 30vmin;
}

.App-header {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.morgondag-logo {
  max-width: 45%;
  margin-bottom: 10vmin
}

.App-link {
  color: #507d8f;
  font-size:0.8em;
}

@keyframes bg-amin {
  from {
    background-position:0 0
  }
  to {
    background-position:0 -768px
  }
}
